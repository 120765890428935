/**
 * @generated SignedSource<<04c361ace99dc040633952258d15e745>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LeaderboardDashboardBlockQuery$variables = {
  organizationId: string;
  productId: string;
  blockId: string;
};
export type LeaderboardDashboardBlockQuery$data = {
  readonly organization: {
    readonly viewerLeaderboard?: {
      readonly score: number;
      readonly position: number | null;
      readonly member: {
        readonly id: string;
        readonly fullName: string;
        readonly bio: string;
        readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarWithDetailsFragment" | "LeaderboardDashboardBlockCarouselItemFragment">;
      };
    } | null;
    readonly leaderboard?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly score: number;
          readonly member: {
            readonly id: string;
            readonly fullName: string;
            readonly bio: string;
            readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarWithDetailsFragment" | "LeaderboardDashboardBlockCarouselItemFragment">;
          };
        };
      }>;
    };
  } | null;
  readonly product: {
    readonly viewerLeaderboard?: {
      readonly score: number;
      readonly position: number | null;
      readonly member: {
        readonly id: string;
        readonly fullName: string;
        readonly bio: string;
        readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarWithDetailsFragment" | "LeaderboardDashboardBlockCarouselItemFragment">;
      };
    } | null;
    readonly leaderboard?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly score: number;
          readonly member: {
            readonly id: string;
            readonly fullName: string;
            readonly bio: string;
            readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarWithDetailsFragment" | "LeaderboardDashboardBlockCarouselItemFragment">;
          };
        };
      }>;
    };
  } | null;
};
export type LeaderboardDashboardBlockQuery = {
  variables: LeaderboardDashboardBlockQuery$variables;
  response: LeaderboardDashboardBlockQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "blockId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "blockId",
    "variableName": "blockId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "score",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "position",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bio",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "member",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileAvatarWithDetailsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LeaderboardDashboardBlockCarouselItemFragment"
    }
  ],
  "storageKey": null
},
v11 = [
  {
    "alias": null,
    "args": (v4/*: any*/),
    "concreteType": "Leaderboard",
    "kind": "LinkedField",
    "name": "viewerLeaderboard",
    "plural": false,
    "selections": [
      (v5/*: any*/),
      (v6/*: any*/),
      (v10/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v4/*: any*/),
    "concreteType": "LeaderboardNodeConnection",
    "kind": "LinkedField",
    "name": "leaderboard",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LeaderboardNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Leaderboard",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v5/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v12 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "productId"
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "member",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatar",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTest",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = [
  {
    "alias": null,
    "args": (v4/*: any*/),
    "concreteType": "Leaderboard",
    "kind": "LinkedField",
    "name": "viewerLeaderboard",
    "plural": false,
    "selections": [
      (v5/*: any*/),
      (v6/*: any*/),
      (v14/*: any*/),
      (v7/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v4/*: any*/),
    "concreteType": "LeaderboardNodeConnection",
    "kind": "LinkedField",
    "name": "leaderboard",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LeaderboardNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Leaderboard",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v5/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LeaderboardDashboardBlockQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": (v11/*: any*/),
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "product",
        "args": (v12/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": (v11/*: any*/),
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "LeaderboardDashboardBlockQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v13/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": (v15/*: any*/),
            "type": "Organization",
            "abstractKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "product",
        "args": (v12/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v13/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": (v15/*: any*/),
            "type": "Product",
            "abstractKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "76ad78dccb1d6bd07d5e9fb9e03cd95a",
    "id": null,
    "metadata": {},
    "name": "LeaderboardDashboardBlockQuery",
    "operationKind": "query",
    "text": "query LeaderboardDashboardBlockQuery(\n  $organizationId: ID!\n  $productId: ID!\n  $blockId: ID!\n) {\n  organization: node(id: $organizationId) {\n    __typename\n    ... on Organization {\n      viewerLeaderboard(blockId: $blockId) {\n        score\n        position\n        member {\n          id\n          fullName\n          bio\n          ...ProfileAvatarWithDetailsFragment\n          ...LeaderboardDashboardBlockCarouselItemFragment\n        }\n        id\n      }\n      leaderboard(blockId: $blockId) {\n        edges {\n          node {\n            id\n            score\n            member {\n              id\n              fullName\n              bio\n              ...ProfileAvatarWithDetailsFragment\n              ...LeaderboardDashboardBlockCarouselItemFragment\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n  product: node(id: $productId) {\n    __typename\n    ... on Product {\n      viewerLeaderboard(blockId: $blockId) {\n        score\n        position\n        member {\n          id\n          fullName\n          bio\n          ...ProfileAvatarWithDetailsFragment\n          ...LeaderboardDashboardBlockCarouselItemFragment\n        }\n        id\n      }\n      leaderboard(blockId: $blockId) {\n        edges {\n          node {\n            id\n            score\n            member {\n              id\n              fullName\n              bio\n              ...ProfileAvatarWithDetailsFragment\n              ...LeaderboardDashboardBlockCarouselItemFragment\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment LeaderboardDashboardBlockCarouselItemFragment on User {\n  id\n  fullName\n  ...ProfileAvatarFragment\n}\n\nfragment ProfileAvatarFragment on User {\n  id\n  fullName\n  avatar\n  isTest\n}\n\nfragment ProfileAvatarWithDetailsFragment on User {\n  id\n  fullName\n  ...ProfileAvatarFragment\n}\n"
  }
};
})();

(node as any).hash = "a6555ba9804c2fdb27dbd89e3e3cd969";

export default node;
