/**
 * @generated SignedSource<<cf7964173c08dc7026cc17ff2c5e0500>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationRole = "owner" | "admin" | "member" | "%future added value";
export type ProductRole = "manager" | "instructor" | "member" | "%future added value";
export type MembersListDashboardBlockQuery$variables = {
  organizationId: string;
  productId: string;
  count: number;
  organizationRoles?: ReadonlyArray<OrganizationRole> | null;
  productRoles?: ReadonlyArray<ProductRole> | null;
};
export type MembersListDashboardBlockQuery$data = {
  readonly organization: {
    readonly organizationMemberships?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly member: {
            readonly id: string;
            readonly bio: string;
            readonly fullName: string;
            readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarWithDetailsFragment">;
          };
        };
      }>;
    };
  } | null;
  readonly product: {
    readonly productMemberships?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly member: {
            readonly id: string;
            readonly bio: string;
            readonly fullName: string;
            readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarWithDetailsFragment">;
          };
        };
      }>;
    };
  } | null;
};
export type MembersListDashboardBlockQuery = {
  variables: MembersListDashboardBlockQuery$variables;
  response: MembersListDashboardBlockQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationRoles"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productRoles"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v6 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "count"
},
v7 = [
  (v6/*: any*/),
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "members_list"
  },
  {
    "kind": "Variable",
    "name": "roles",
    "variableName": "organizationRoles"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bio",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v11 = [
  (v8/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "member",
    "plural": false,
    "selections": [
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ProfileAvatarWithDetailsFragment"
      }
    ],
    "storageKey": null
  }
],
v12 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "productId"
  }
],
v13 = [
  (v6/*: any*/),
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": {
      "direction": "DESC",
      "field": "last_activity_at"
    }
  },
  {
    "kind": "Variable",
    "name": "roles",
    "variableName": "productRoles"
  }
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v15 = [
  (v8/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "member",
    "plural": false,
    "selections": [
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "avatar",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isTest",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MembersListDashboardBlockQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "OrganizationMembershipNodeConnection",
                "kind": "LinkedField",
                "name": "organizationMemberships",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationMembershipNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationMembership",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v11/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "product",
        "args": (v12/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v13/*: any*/),
                "concreteType": "ProductMembershipNodeConnection",
                "kind": "LinkedField",
                "name": "productMemberships",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductMembershipNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductMembership",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v11/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "MembersListDashboardBlockQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v14/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "OrganizationMembershipNodeConnection",
                "kind": "LinkedField",
                "name": "organizationMemberships",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationMembershipNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationMembership",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v15/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "product",
        "args": (v12/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v14/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v13/*: any*/),
                "concreteType": "ProductMembershipNodeConnection",
                "kind": "LinkedField",
                "name": "productMemberships",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductMembershipNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductMembership",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v15/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Product",
            "abstractKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "12d1d287217822e741f0f31f5fa52256",
    "id": null,
    "metadata": {},
    "name": "MembersListDashboardBlockQuery",
    "operationKind": "query",
    "text": "query MembersListDashboardBlockQuery(\n  $organizationId: ID!\n  $productId: ID!\n  $count: Int!\n  $organizationRoles: [OrganizationRole!]\n  $productRoles: [ProductRole!]\n) {\n  organization: node(id: $organizationId) {\n    __typename\n    ... on Organization {\n      organizationMemberships(first: $count, orderBy: members_list, roles: $organizationRoles) {\n        edges {\n          node {\n            id\n            member {\n              id\n              bio\n              fullName\n              ...ProfileAvatarWithDetailsFragment\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n  product: node(id: $productId) {\n    __typename\n    ... on Product {\n      productMemberships(first: $count, orderBy: {field: \"last_activity_at\", direction: DESC}, roles: $productRoles) {\n        edges {\n          node {\n            id\n            member {\n              id\n              bio\n              fullName\n              ...ProfileAvatarWithDetailsFragment\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment ProfileAvatarFragment on User {\n  id\n  fullName\n  avatar\n  isTest\n}\n\nfragment ProfileAvatarWithDetailsFragment on User {\n  id\n  fullName\n  ...ProfileAvatarFragment\n}\n"
  }
};
})();

(node as any).hash = "78843350035ecf4d6d803b8c81a950fb";

export default node;
