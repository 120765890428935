import ContentUsageGridItem, {
  ContentUsageGridItemSkeleton,
} from "@/content-usage/modules/grid/ContentUsageGridItem"
import { ContentUsageGridItemFragment$key } from "@/content-usage/modules/grid/__generated__/ContentUsageGridItemFragment.graphql"
import { useDashboardBlockCarouselSize } from "@/dashboard/util/useDashboardBlockCarouselSize"
import OccurrenceListItem from "@/product/course/event/list/item/OccurrenceListItem"
import { OccurrenceListItemFragment$key } from "@/product/course/event/list/item/__generated__/OccurrenceListItemFragment.graphql"
import Relay from "@/relay/relayUtils"
import useTrackUserActivity from "@/reporting/useTrackUserActivity"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoCarousel, DiscoEmptyState } from "@disco-ui"
import { graphql, useLazyLoadQuery, usePaginationFragment } from "react-relay"
import DashboardBlockAdminDropdown from "../../DashboardBlockAdminDropdown"
import {
  RecentlyViewedDashboardBlockContentPaginationFragment$data,
  RecentlyViewedDashboardBlockContentPaginationFragment$key,
} from "./__generated__/RecentlyViewedDashboardBlockContentPaginationFragment.graphql"
import { RecentlyViewedDashboardBlockContentPaginationQuery } from "./__generated__/RecentlyViewedDashboardBlockContentPaginationQuery.graphql"
import { RecentlyViewedDashboardBlockContentQuery } from "./__generated__/RecentlyViewedDashboardBlockContentQuery.graphql"
import { RecentlyViewedDashboardBlockFragment$data } from "./__generated__/RecentlyViewedDashboardBlockFragment.graphql"

type CarouselNode =
  | RecentlyViewedDashboardBlockContentPaginationFragment$data["recentlyViewedContentUsages"]["edges"][0]["node"]
  | RecentlyViewedDashboardBlockContentPaginationFragment$data["recentlyViewedOccurrences"]["edges"][0]["node"]

interface Props {
  block: RecentlyViewedDashboardBlockFragment$data
}

export default function RecentlyViewedDashboardBlockContent({ block }: Props) {
  const classes = useStyles()
  const { sliceSize, ...carouselProps } = useDashboardBlockCarouselSize(3, block, "left")
  const trackUserActivity = useTrackUserActivity()

  const { recentlyViewedBlock } =
    useLazyLoadQuery<RecentlyViewedDashboardBlockContentQuery>(
      graphql`
        query RecentlyViewedDashboardBlockContentQuery($id: ID!, $first: Int) {
          recentlyViewedBlock: node(id: $id) {
            ... on RecentlyViewedDashboardBlock {
              id
              ...RecentlyViewedDashboardBlockContentPaginationFragment
                @arguments(first: $first)
            }
          }
        }
      `,
      {
        id: block.id,
        first: sliceSize,
      }
    )

  const { data, loadNext, hasNext, hasPrevious, isLoadingNext } = usePaginationFragment<
    RecentlyViewedDashboardBlockContentPaginationQuery,
    RecentlyViewedDashboardBlockContentPaginationFragment$key
  >(
    graphql`
      fragment RecentlyViewedDashboardBlockContentPaginationFragment on RecentlyViewedDashboardBlock
      @refetchable(queryName: "RecentlyViewedDashboardBlockContentPaginationQuery")
      @argumentDefinitions(first: { type: "Int" }, after: { type: "String" }) {
        id
        recentlyViewedContentUsages(first: $first, after: $after) {
          __id
          totalCount
          edges {
            node {
              __typename
              id
              product {
                slug
              }
              ...ContentUsageGridItemFragment
            }
          }
        }
        recentlyViewedOccurrences(first: $first, after: $after)
          @connection(
            key: "RecentlyViewedDashboardBlockContent__recentlyViewedOccurrences"
          ) {
          __id
          totalCount
          edges {
            node {
              __typename
              id

              product {
                slug
              }

              ...OccurrenceListItemFragment
            }
          }
        }
      }
    `,
    recentlyViewedBlock
  )

  // Alternate the order of recently viewed content usages and event recordings
  const recentlyViewedContentUsages = Relay.connectionToArray(
    data?.recentlyViewedContentUsages
  )
  const recentlyViewedOccurrences = Relay.connectionToArray(
    data?.recentlyViewedOccurrences
  )

  // Alternate content usage and event recording cards
  const recentlyViewed: CarouselNode[] = []
  const l = Math.min(recentlyViewedContentUsages.length, recentlyViewedOccurrences.length)

  for (let i = 0; i < l; i++) {
    recentlyViewed.push(recentlyViewedContentUsages[i], recentlyViewedOccurrences[i])
  }

  recentlyViewed.push(
    ...recentlyViewedContentUsages.slice(l),
    ...recentlyViewedOccurrences.slice(l)
  )

  return (
    <DiscoCarousel
      title={"Recently Viewed"}
      isDashboardBlock
      data={recentlyViewed}
      item={(item, idx) => {
        if (Relay.isNodeType(item, "ContentUsage")) {
          return (
            <ContentUsageGridItem
              key={item.id}
              contentUsageKey={item as ContentUsageGridItemFragment$key}
              testid={`RecentlyViewedDashboardBlockContent.item-${idx}`}
              showOverflow={false}
              className={classes.item}
              navigateOnClick
              onClick={handleTrackUserActivity}
            />
          )
        } else if (Relay.isNodeType(item, "Occurrence")) {
          return (
            <OccurrenceListItem
              testid={`RecentlyViewedDashboardBlockContent.card-${idx}`}
              variant={"card"}
              occurrenceKey={item as OccurrenceListItemFragment$key}
              navigateOnClick
              onClick={handleTrackUserActivity}
            />
          )
        }
        return <></>
      }}
      itemSkeleton={<ContentUsageGridItemSkeleton />}
      totalCount={recentlyViewed.length || 0}
      moreActions={<DashboardBlockAdminDropdown dashboardBlockKey={block} />}
      refetch={{
        hasNext,
        hasPrevious,
        loadMore: () => loadNext(sliceSize),
        isLoading: isLoadingNext,
      }}
      emptyState={
        <DiscoEmptyState
          className={classes.emptyState}
          testid={"RecentlyViewedDashboardBlockContent"}
          title={"No Recently Viewed"}
          variant={"compact"}
        />
      }
      {...carouselProps}
    />
  )

  function handleTrackUserActivity() {
    trackUserActivity({
      kind: "clicked",
      entityId: block.id,
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  item: {
    width: "unset",
  },
  emptyState: {
    maxHeight: "226px",
    padding: theme.spacing(6.5, 4),
    marginBottom: theme.spacing(1.5),
    backgroundColor: theme.palette.groovy.neutral[100],
    borderRadius: theme.measure.borderRadius.xl,
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
    color: theme.palette.text.primary,
  },
}))
